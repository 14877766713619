sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.archive=sv.i18n.portlet.archive || {};
sv.i18n.portlet.archive.archive=sv.i18n.portlet.archive.archive || {};
sv.i18n.portlet.archive.archive.de={"creatingArticle": "Artikel wird erstellt..."};
sv.i18n.portlet.archive.archive.en={"creatingArticle": "Creating article..."};
sv.i18n.portlet.archive.archive.es={"creatingArticle": "Creando art铆culo..."};
sv.i18n.portlet.archive.archive.fi={"creatingArticle": "Luo artikkelia..."};
sv.i18n.portlet.archive.archive.fr={"creatingArticle": "Cr茅er des articles..."};
sv.i18n.portlet.archive.archive.no={"creatingArticle": "Oppretter artikkel ..."};
sv.i18n.portlet.archive.archive.sv={"creatingArticle": "Skapar artikel..."};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.blog=sv.i18n.portlet.blog || {};
sv.i18n.portlet.blog.blog=sv.i18n.portlet.blog.blog || {};
sv.i18n.portlet.blog.blog.de={"creatingBlogEntry": "Blogbeitrag wird erstellt..."};
sv.i18n.portlet.blog.blog.en={"creatingBlogEntry": "Creating blog entry..."};
sv.i18n.portlet.blog.blog.es={"creatingBlogEntry": "Creando entrada de blog..."};
sv.i18n.portlet.blog.blog.fi={"creatingBlogEntry": "Luo blogiyhteytt盲..."};
sv.i18n.portlet.blog.blog.fr={"creatingBlogEntry": "Cr茅er des articles de blog..."};
sv.i18n.portlet.blog.blog.no={"creatingBlogEntry": "Oppretter blogginnlegg ..."};
sv.i18n.portlet.blog.blog.sv={"creatingBlogEntry": "Skapar blogginl盲gg..."};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.comments2=sv.i18n.portlet.comments2 || {};
sv.i18n.portlet.comments2.comments=sv.i18n.portlet.comments2.comments || {};
sv.i18n.portlet.comments2.comments.de={
   "moderatedBody": "Die Kommentarfunktion wird moderiert.\nIhr Kommentar wird erst sichtbar, wenn ein Moderator ihn genehmigt hat.",
   "removeReplyTitle": "Antwort entfernen",
   "noNameHeading": "Kein Name",
   "showLess": "Weniger anzeigen",
   "postFailedHeading": "FEHLER",
   "hiddenComment": "Verborgener Kommentar",
   "removeReplyBody": "Sind Sie sicher, dass Sie die Antwort l枚schen m枚chten?",
   "editReplyTitle": "Antwort bearbeiten",
   "removeCommentBody": "Sind Sie sicher, dass Sie den Kommentar l枚schen m枚chten?",
   "moderatedHeading": "Modereriertes Kommentarfeld",
   "doReply": "Antworten",
   "removeCommentTitle": "Kommentar entfernen",
   "reply": "Antworten",
   "commentToLongHeading": "Zu lang",
   "replies": "Antworten",
   "hiddenReply": "Verborgene Antwort",
   "commentToLongBody": "Der Kommentar, den Sie senden wollten, war zu lang.",
   "editCommentTitle": "Kommentar bearbeiten",
   "save": "Speichern",
   "showMore": "Mehr anzeigen",
   "postFailedBody": "Es ist ein Fehler aufgetreten, der Kommentar wurde nicht ver枚ffentlicht.",
   "noNameBody": "Kein Name angegeben Name ist obligatorisch"
};
sv.i18n.portlet.comments2.comments.en={
   "moderatedBody": "The comments function is moderated.\nYour comment will become visible only after a moderator has approved it.",
   "removeReplyTitle": "Delete reply",
   "noNameHeading": "No name",
   "showLess": "Show less",
   "postFailedHeading": "ERROR",
   "hiddenComment": "Hidden comment",
   "removeReplyBody": "Are you sure you want to delete the reply?",
   "editReplyTitle": "Edit reply",
   "removeCommentBody": "Are you sure you want to delete the comment?",
   "moderatedHeading": "Moderated comments field",
   "doReply": "Reply",
   "removeCommentTitle": "Delete comment",
   "reply": "reply",
   "commentToLongHeading": "Too long",
   "replies": "replies",
   "hiddenReply": "Hidden reply",
   "showMore": "Show more",
   "commentToLongBody": "The comment you where trying to post was too long.",
   "editCommentTitle": "Edit comment",
   "save": "Save",
   "postFailedBody": "An error has occurred, the comment was not published.",
   "noNameBody": "No name given. Name is mandatory"
};
sv.i18n.portlet.comments2.comments.es={
   "moderatedBody": "La funci贸n de comentarios tiene moderador.\nTu comentario ser谩 visible cuando el moderador lo apruebe.",
   "removeReplyTitle": "Eliminar respuesta",
   "noNameHeading": "Sin nombre",
   "showLess": "Mostrar menos",
   "postFailedHeading": "ERROR",
   "hiddenComment": "Comentario oculto",
   "removeReplyBody": "驴Est谩s seguro de que deseas eliminar la respuesta?",
   "editReplyTitle": "Editar respuesta",
   "removeCommentBody": "驴Est谩s seguro de que deseas eliminar el comentario?",
   "moderatedHeading": "Campo de comentario con moderador",
   "doReply": "Responder",
   "removeCommentTitle": "Eliminar comentario",
   "reply": "respuesta",
   "commentToLongHeading": "Demasiado largo",
   "replies": "respuesta",
   "hiddenReply": "Respuesta oculta",
   "commentToLongBody": "El comentario que intentabas enviar era demasiado largo...",
   "editCommentTitle": "Editar comentario",
   "save": "Guardar",
   "showMore": "Mostrar m谩s",
   "postFailedBody": "Ha ocurrido un error, el comentario no se ha publicado.",
   "noNameBody": "No se ha indicado nombre. El nombre es obligatorio"
};
sv.i18n.portlet.comments2.comments.fi={
   "removeCommentBody": "Oletko varma, ett盲 haluat poistaa kommentin?",
   "commentToLongHeading": "Liian pitk盲",
   "removeCommentTitle": "Poista kommentti",
   "showMore": "N盲yt盲 lis盲盲",
   "noNameBody": "Nime盲 ei annettu. Nimi on pakollinen",
   "showLess": "N盲yt盲 v盲hemm盲n",
   "commentToLongBody": "Yritit l盲hett盲盲 liian pitk盲n kommentin.",
   "moderatedHeading": "Moderoitu kommenttikentt盲",
   "save": "Tallenna",
   "moderatedBody": "Kommentointitoiminto on moderoitu.\nKommenttisi tulee n盲kyviin, kun moderaattori on hyv盲ksynyt sen.",
   "hiddenComment": "Poistunut kommentti",
   "noNameHeading": "Ei nime盲",
   "postFailedHeading": "VIRHE",
   "postFailedBody": "Tapahtui virhe, kommenttia ei julkaistu."
};
sv.i18n.portlet.comments2.comments.fr={
   "moderatedBody": "La fonction de commentaire est mod茅r茅e.\nVotre commentaire ne sera visible que lorsqu'un mod茅rateur l'aura approuv茅.",
   "removeReplyTitle": "Supprimer les r茅ponses",
   "noNameHeading": "Pas de nom",
   "showLess": "Afficher moins",
   "postFailedHeading": "ERREUR",
   "hiddenComment": "Commentaire masqu茅",
   "removeReplyBody": "Voulez-vous vraiment supprimer la r茅ponse聽?",
   "editReplyTitle": "Editer la r茅ponse",
   "removeCommentBody": "Voulez-vous vraiment supprimer le commentaire聽?",
   "moderatedHeading": "Champ de commentaires mod茅r茅",
   "doReply": "R茅pondre",
   "removeCommentTitle": "Supprimer le commentaire",
   "reply": "r茅ponse",
   "commentToLongHeading": "Trop long",
   "replies": "r茅ponse",
   "hiddenReply": "R茅ponse masqu茅e",
   "commentToLongBody": "Le commentaire que vous avez essay茅 d'envoyer 茅tait trop long.",
   "editCommentTitle": "Editer le commentaire",
   "save": "Sauvegarder",
   "showMore": "Afficher plus",
   "postFailedBody": "Une erreur s'est produite, le commentaire n'a pas 茅t茅 publi茅.",
   "noNameBody": "Aucun nom n'est indiqu茅. Le nom est obligatoire"
};
sv.i18n.portlet.comments2.comments.no={
   "Din": "kommentar blir synlig f酶rst n氓r en moderator har godkjent den.",
   "moderatedBody": "Kommentarfunksjonen er moderert.",
   "removeReplyTitle": "Fjern svar",
   "noNameHeading": "Intet navn",
   "showLess": "Vis mindre",
   "postFailedHeading": "FEIL",
   "hiddenComment": "Skjult kommentar",
   "removeReplyBody": "Er du sikker p氓 at du vil fjerne svaret?",
   "editReplyTitle": "Rediger svar",
   "removeCommentBody": "Er du sikker p氓 at du vil fjerne kommentaren?",
   "moderatedHeading": "Moderert kommentarfelt",
   "doReply": "Svar",
   "removeCommentTitle": "Fjern kommentar",
   "reply": "svar",
   "commentToLongHeading": "For lang",
   "replies": "svar",
   "hiddenReply": "Skjult svar",
   "showMore": "Vis mer",
   "commentToLongBody": "Kommentaren du fors酶kte 氓 sende, var for lang.",
   "save": "Lagre",
   "editCommentTitle": "Rediger kommentar",
   "postFailedBody": "Det oppsto en feil, kommentaren ble ikke publisert.",
   "noNameBody": "Intet navn angitt. Navn er obligatorisk"
};
sv.i18n.portlet.comments2.comments.sv={
   "moderatedBody": "Kommentarsfunktionen 盲r modererad.\nDin kommentar blir synlig f枚rst n盲r en moderator godk盲nt den.",
   "removeReplyTitle": "Ta bort svar",
   "noNameHeading": "Inget namn",
   "showLess": "Visa mindre",
   "postFailedHeading": "FEL",
   "hiddenComment": "Dold kommentar",
   "removeReplyBody": "脛r du s盲ker p氓 att du vill ta bort svaret?",
   "editReplyTitle": "Redigera svar",
   "removeCommentBody": "脛r du s盲ker p氓 att du vill ta bort kommentaren?",
   "moderatedHeading": "Modererat kommentarsf盲lt",
   "doReply": "Svara",
   "removeCommentTitle": "Ta bort kommentar",
   "reply": "svar",
   "commentToLongHeading": "F枚r l氓ng",
   "replies": "svar",
   "hiddenReply": "Dolt svar",
   "showMore": "Visa mer",
   "commentToLongBody": "Kommentaren du f枚rs枚kte skicka var f枚r l氓ng.",
   "editCommentTitle": "Redigera kommentar",
   "save": "Spara",
   "postFailedBody": "Ett fel har uppst氓tt, kommentaren publicerades inte.",
   "noNameBody": "Inget namn angivet. Namn 盲r obligatoriskt"
};
sv.i18n.common=sv.i18n.common || {};
sv.i18n.common.de={
   "networkErrorText": "Die Information konnte nicht aktualisiert werden. Es konnte kein Kontakt zum Server hergestellt werden.",
   "networkErrorTitle": "Fehler bei der Serverkommunikation",
   "decimal": ",",
   "cancel": "Abbrechen",
   "mins": "Min",
   "error-fileExists": "{0} ist bereits vorhanden.",
   "ok": "OK",
   "error-fileRenameHeading": "Der Name der Datei konnte nicht ge盲ndert werden",
   "add": "Hinzuf眉gen",
   "close": "Beenden",
   "new": "Neu",
   "error": "Fehler",
   "error-fileExistsHeading": "Die Datei konnte nicht hinzugef眉gt werden",
   "help": "Hilfe",
   "save": "Speichern",
   "hrs": "Std.",
   "error-unknown": "Unbekannter Fehler.",
   "hr": "Std.",
   "remove": "L枚schen"
};
sv.i18n.common.en={
   "networkErrorText": "Information could not be updated. Could not contact server.",
   "networkErrorTitle": "Communication with server failed",
   "decimal": ".",
   "cancel": "Cancel",
   "mins": "mins",
   "error-fileExists": "{0} already exists.",
   "ok": "OK",
   "error-fileRenameHeading": "Could not rename file",
   "add": "Add",
   "close": "Close",
   "new": "New",
   "error": "Error",
   "error-fileExistsHeading": "Could not add file",
   "help": "Help",
   "save": "Save",
   "hrs": "hrs",
   "error-unknown": "Unknown error.",
   "remove": "Delete",
   "hr": "hr"
};
sv.i18n.common.es={
   "networkErrorText": "No se ha podido actualizar la informaci贸n. No se ha podido establecer contacto con el servidor.",
   "networkErrorTitle": "Error en la comunicaci贸n con el servidor.",
   "decimal": ",",
   "cancel": "Cancelar",
   "mins": "min",
   "error-fileExists": "{0} ya existe.",
   "ok": "Aceptar",
   "error-fileRenameHeading": "No se ha podido cambiar el nombre del fichero",
   "add": "A帽adir",
   "close": "Cerrar",
   "new": "Nuevo",
   "error": "Error",
   "error-fileExistsHeading": "No se ha podido a帽adir el fichero",
   "help": "Ayuda",
   "save": "Guardar",
   "hrs": "hora",
   "error-unknown": "Error desconocido.",
   "hr": "hora",
   "remove": "Eliminar"
};
sv.i18n.common.fi={
   "networkErrorText": "Aineistoa ei voitu p盲ivitt盲盲. Palvelimeen ei saada yhteytt盲.",
   "networkErrorTitle": "Ongelma palvelinyhteydess盲.",
   "decimal": ",",
   "cancel": "Peruuta",
   "mins": "min",
   "error-fileExists": "{0} tiedosto on jo olemassa.",
   "ok": "OK",
   "error-fileRenameHeading": "Tiedoston nimen vaihto ei onnistunut",
   "add": "Luo tili",
   "close": "Sulje",
   "new": "Uusi",
   "error": "Virhe",
   "error-fileExistsHeading": "Tiedoston lis盲盲minen ei onnistunut",
   "help": "Ohje",
   "save": "Tallenna",
   "hrs": "tuntia",
   "error-unknown": "Tuntematon virhe.",
   "remove": "Poista",
   "hr": "tunti"
};
sv.i18n.common.fr={
   "networkErrorText": "Les informations n'ont pas pu 锚tre mises 脿 jour. Impossible de contacter le serveur.",
   "networkErrorTitle": "Erreurs de communication avec le serveur",
   "decimal": ",",
   "cancel": "Annuler",
   "mins": "min",
   "error-fileExists": "{0} existe d茅j脿.",
   "ok": "OK",
   "error-fileRenameHeading": "Le fichier n'a pas pu 锚tre renomm茅",
   "add": "Ajouter",
   "close": "Fermer",
   "new": "Nouveau",
   "error": "Erreur",
   "error-fileExistsHeading": "Impossible d'ajouter le fichier",
   "help": "Aide",
   "save": "Sauvegarder",
   "hrs": "heure",
   "error-unknown": "Erreur inconnue.",
   "hr": "heure",
   "remove": "Supprimer"
};
sv.i18n.common.no={
   "networkErrorText": "Informasjonen kunne ikke oppdateres. Kunne ikke kontakte serveren.",
   "networkErrorTitle": "Feil ved serverkommunikasjon",
   "decimal": ",",
   "cancel": "Avbryt",
   "mins": "min",
   "error-fileExists": "{0} finnes allerede.",
   "ok": "OK",
   "error-fileRenameHeading": "Det var ikke mulig 氓 bytte navn p氓 filen",
   "add": "Legg til",
   "close": "Lukk",
   "new": "Ny",
   "error": "Feil",
   "error-fileExistsHeading": "Det var ikke mulig 氓 legge til filen",
   "help": "Hjelp",
   "save": "Lagre",
   "hrs": "time",
   "error-unknown": "Ukjent feil.",
   "remove": "Fjern",
   "hr": "time"
};
sv.i18n.common.pt={"cancel": "Cancelar"};
sv.i18n.common.sv={
   "networkErrorText": "Informationen kunde inte uppdateras. Kunde inte kontakta servern.",
   "networkErrorTitle": "Fel vid serverkommunikation",
   "decimal": ",",
   "cancel": "Avbryt",
   "mins": "min",
   "error-fileExists": "{0} finns redan.",
   "ok": "OK",
   "error-fileRenameHeading": "Det gick inte att byta namn p氓 filen",
   "add": "L盲gg till",
   "close": "St盲ng",
   "new": "Ny",
   "error": "Fel",
   "error-fileExistsHeading": "Det gick inte att l盲gga till filen",
   "help": "Hj盲lp",
   "save": "Spara",
   "hrs": "tim",
   "error-unknown": "Ok盲nt fel.",
   "remove": "Ta bort",
   "hr": "tim"
};
sv.i18n.util=sv.i18n.util || {};
sv.i18n.util.dialogutil=sv.i18n.util.dialogutil || {};
sv.i18n.util.dialogutil.de={
   "ok": "OK",
   "cancel": "Abbrechen"
};
sv.i18n.util.dialogutil.en={
   "ok": "OK",
   "cancel": "Cancel"
};
sv.i18n.util.dialogutil.es={
   "ok": "Aceptar",
   "cancel": "Cancelar"
};
sv.i18n.util.dialogutil.fi={
   "ok": "OK",
   "cancel": "Peruuta"
};
sv.i18n.util.dialogutil.fr={
   "ok": "OK",
   "cancel": "Annuler"
};
sv.i18n.util.dialogutil.no={
   "ok": "OK",
   "cancel": "Avbryt"
};
sv.i18n.util.dialogutil.sv={
   "ok": "OK",
   "cancel": "Avbryt"
};
sv.i18n.util=sv.i18n.util || {};
sv.i18n.util.errorutil=sv.i18n.util.errorutil || {};
sv.i18n.util.errorutil.de={
   "errorMessage": "Es ist ein Problem aufgetreten, das dazu gef眉hrt hat, dass die Aufgabe nicht wie vorgesehen ausgef眉hrt werden konnte ({0})",
   "connectionErrorMessageTitle": "Verbindungsproblem",
   "errorMessageTitle": "Es ist ein Fehler aufgetreten",
   "connectionErrorMessage": "Es konnte keine Verbindung zum Server hergestellt werden. Bitte versuchen Sie es sp盲ter erneut"
};
sv.i18n.util.errorutil.en={
   "errorMessage": "A problem occurred that prevented the task from being performed ({0})",
   "connectionErrorMessageTitle": "Connection error",
   "errorMessageTitle": "An error occurred",
   "connectionErrorMessage": "Could not connect to the server, please try again later"
};
sv.i18n.util.errorutil.es={
   "errorMessage": "Se produjo un problema que impidi贸 que la tarea se realizara seg煤n lo previsto ({0}).",
   "connectionErrorMessageTitle": "Problema de conexi贸n",
   "errorMessageTitle": "Ha ocurrido un error",
   "connectionErrorMessage": "No se ha podido conectar con el servidor. Int茅ntalo de nuevo m谩s tarde."
};
sv.i18n.util.errorutil.fi={
   "errorMessage": "Toimintoa ei voitu virheen takia suorittaa pyydetyll盲 tavalla ({0})",
   "connectionErrorMessageTitle": "Yhteysongelma",
   "errorMessageTitle": "Tapahtui virhe",
   "connectionErrorMessage": "Palvelimeen ei saatu yhteytt盲, kokeile my枚hemmin uudelleen"
};
sv.i18n.util.errorutil.fr={
   "errorMessage": "Un probl猫me s'est produit qui a emp锚ch茅 l'ex茅cution de la t芒che comme pr茅vu ({0}).",
   "connectionErrorMessageTitle": "Probl猫mes de connexion",
   "errorMessageTitle": "Une erreur s'est produite",
   "connectionErrorMessage": "Impossible de se connecter au serveur, veuillez r茅essayer plus tard"
};
sv.i18n.util.errorutil.no={
   "errorMessage": "Det oppsto et problem som gjorde at opplysningen ikke kunne utf酶res slik det var tenkt ({0})",
   "connectionErrorMessageTitle": "Tilkoblingsproblem",
   "errorMessageTitle": "Det oppsto en feil",
   "connectionErrorMessage": "Kunne ikke koble til serveren, pr酶v igjen senere"
};
sv.i18n.util.errorutil.sv={
   "errorMessage": "Ett problem uppstod som gjorde att uppgiften inte kunde utf枚ras som det var t盲nkt ({0})",
   "connectionErrorMessageTitle": "Anslutningsproblem",
   "errorMessageTitle": "Ett fel intr盲ffade",
   "connectionErrorMessage": "Kunde inte ansluta till servern, var god f枚rs枚k igen senare"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.search=sv.i18n.portlet.search || {};
sv.i18n.portlet.search.searchform2=sv.i18n.portlet.search.searchform2 || {};
sv.i18n.portlet.search.searchform2.searchform2=sv.i18n.portlet.search.searchform2.searchform2 || {};
sv.i18n.portlet.search.searchform2.searchform2.de={
   "ugc": "Benutzergenerierte Inhalte",
   "results": "Suchergebnis",
   "content": "Inhalt",
   "searchFailed": "Die Suche mit dem Fehlercode {0} ist fehlgeschlagen.",
   "users": "Personen"
};
sv.i18n.portlet.search.searchform2.searchform2.en={
   "ugc": "User generated content",
   "results": "search results",
   "content": "Content",
   "searchFailed": "Search failed with error code {0}.",
   "users": "Users"
};
sv.i18n.portlet.search.searchform2.searchform2.es={
   "ugc": "Contenido generado por el usuario",
   "results": "Resultado de b煤squeda",
   "content": "Contenido",
   "searchFailed": "La b煤squeda fall贸 con el c贸digo de error {0}.",
   "users": "Personas"
};
sv.i18n.portlet.search.searchform2.searchform2.fi={"searchFailed": "Haku ep盲onnistui, virhekoodi {0}."};
sv.i18n.portlet.search.searchform2.searchform2.fr={
   "ugc": "Contenu g茅n茅r茅 par les utilisateurs",
   "results": "r茅sultat de recherche",
   "content": "Contenu",
   "searchFailed": "La recherche a 茅chou茅 avec le code d'erreur {0}.",
   "users": "Personnes"
};
sv.i18n.portlet.search.searchform2.searchform2.no={
   "ugc": "Brukergenerert innhold",
   "results": "s酶keresultat",
   "content": "Innhold",
   "searchFailed": "S酶ket mislyktes med feilkode {0}.",
   "users": "Personer"
};
sv.i18n.portlet.search.searchform2.searchform2.sv={
   "ugc": "Anv盲ndargenererat inneh氓ll",
   "results": "s枚kresultat",
   "content": "Inneh氓ll",
   "searchFailed": "S枚kningen misslyckades med felkod {0}.",
   "users": "Personer"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.sharepoint=sv.i18n.portlet.sharepoint || {};
sv.i18n.portlet.sharepoint.sharepointfile=sv.i18n.portlet.sharepoint.sharepointfile || {};
sv.i18n.portlet.sharepoint.sharepointfile.de={
   "loginButton": "Anmeldung",
   "connectToSharePoint": "An SharePoint anschlie脽en"
};
sv.i18n.portlet.sharepoint.sharepointfile.en={
   "loginButton": "Log in",
   "connectToSharePoint": "Connect to SharePoint"
};
sv.i18n.portlet.sharepoint.sharepointfile.es={
   "loginButton": "Iniciar sesi贸n",
   "connectToSharePoint": "Con茅ctate a SharePoint"
};
sv.i18n.portlet.sharepoint.sharepointfile.fi={};
sv.i18n.portlet.sharepoint.sharepointfile.fr={
   "loginButton": "Se connecter",
   "connectToSharePoint": "Se connecter 脿 SharePoint"
};
sv.i18n.portlet.sharepoint.sharepointfile.no={
   "loginButton": "Logg inn",
   "connectToSharePoint": "Koble til SharePoint"
};
sv.i18n.portlet.sharepoint.sharepointfile.sv={
   "loginButton": "Logga in",
   "connectToSharePoint": "Anslut till SharePoint"
};
sv.i18n.util=sv.i18n.util || {};
sv.i18n.util.viewutil=sv.i18n.util.viewutil || {};
sv.i18n.util.viewutil.de={"selectView": "Ansicht ausw盲hlen"};
sv.i18n.util.viewutil.en={"selectView": "Select view"};
sv.i18n.util.viewutil.es={"selectView": "Seleccionar vista"};
sv.i18n.util.viewutil.fi={"selectView": "Valitse n盲kym盲"};
sv.i18n.util.viewutil.fr={"selectView": "S茅lectionner une vue"};
sv.i18n.util.viewutil.no={"selectView": "Velg visning"};
sv.i18n.util.viewutil.sv={"selectView": "V盲lj vy"};
